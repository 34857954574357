import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Galleries from "./Galleries";
import { completeddata } from "../projects/commercial/CompletedData";

function CompletedGallery() {
  const data = useStaticQuery(graphql`
    {
      allCompletedJson(
        sort: { fields: order, order: ASC } 
        limit: 10
      ) {
        edges {
          node {
            title
            gallery {
              title
              images {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = completeddata.map((item) => ({
    title: item.title,
    gallery: item.gallery2,
  }));

  return <Galleries data={images} />;
}

export default CompletedGallery;
