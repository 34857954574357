import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import GalleryList from "./GalleryList";

function CSRActivities() {
  const data = useStaticQuery(graphql`
    {
      allCsrActivitiesJson {
        edges {
          node {
            images {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            sub_title
          }
        }
      }
    }
  `);
  let images = data.allCsrActivitiesJson.edges.map(({ node }) => node);

  return (
    <>
      {images.map((item, idx) => (
        <div key={idx} className="pb-4">
          <div className="mb-3">
            <h4 className="pb-2">{item.title}</h4>
            <h6 className="">{item.sub_title}</h6>
          </div>
          <GalleryList
            images={(item.images || []).map((x) => x.childImageSharp.fluid)}
          />
        </div>
      ))}
    </>
  );
}

export default CSRActivities;
