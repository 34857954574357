import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Galleries from "./Galleries";
import PromenadeOngoingData from "../projects/commercial/Promenade/PromenadeOngoingData";

function OngoingGallery() {
  const data = useStaticQuery(graphql`
    {
      allOngoingJson(
        filter: { is_active: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            title
            sections {
              title
              gallery {
                title
                images {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const imagesArray = [];
  const promenadeOngoingData = PromenadeOngoingData();
  const promenadeOngoingData2 = promenadeOngoingData?.[0];

  imagesArray.push({
    title: `${promenadeOngoingData2?.title || ""}`,
    gallery: promenadeOngoingData2?.sections?.[0]?.gallery,
  });

  return <Galleries data={imagesArray} />;
}

export default OngoingGallery;
