import React, { useState } from "react";
import CompletedGallery from "./CompletedGallery";
import OngoingGallery from "./OngoingGallery";
import CompletedGalleryCommercial from "./CompletedGalleryCommercial";
import OngoingGalleryCommercial from "./OngoingGalleryCommercial";
import CSRActivities from "./CSRActivities";

function GalleryContent() {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <section className="residence-section">
      <div className="container">
        <div className="custom-tab-menu">
          <span
            className={`${tabIndex === 0 ? "active" : ""}`}
            onClick={() => setTabIndex(0)}
          >
            Residential
          </span>
          <span
            className={`${tabIndex === 1 ? "active" : ""}`}
            onClick={() => setTabIndex(1)}
          >
            Commercial
          </span>
          <span
            className={`${tabIndex === 2 ? "active" : ""}`}
            onClick={() => setTabIndex(2)}
          >
            CSR Activities
          </span>
        </div>

        {tabIndex === 0 ? (
          <>
            <div className="pb-4" id="synopsis">
              <h5 className="residence-section-title" id="synopsis">
                Residential Ongoing Projects
              </h5>
              <OngoingGallery />
            </div>
            <div className="pb-4" id="synopsis">
              <h5 className="residence-section-title" id="synopsis">
                Residential Completed Projects
              </h5>
              <CompletedGallery />
            </div>
          </>
        ) : tabIndex == 1 ? (
          <>
            <div className="pb-4" id="synopsis">
              <h5 className="residence-section-title" id="synopsis">
                Commercial Ongoing Projects
              </h5>
              <OngoingGalleryCommercial />
            </div>
            <div className="pb-4" id="synopsis">
              <h5 className="residence-section-title" id="synopsis">
                Commercial Completed Projects
              </h5>
              <CompletedGalleryCommercial />
            </div>
          </>
        ) : (
          <>
            <div className="pb-4" id="synopsis">
              <CSRActivities />
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default GalleryContent;
